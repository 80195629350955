.corporate__container {
 width: 100%; 
}
.corporate__container-hero_section {
  display: flex;
  flex-wrap: wrap;
  margin: 87px 0 0 150px;
}
.corporate__container-hero_section-texts {
  width: 35%;
  margin-right: 217px;
}
.corporate__container-hero_section-texts > h1 {
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  color: #FF0000;
  text-align: left;
}
.corporate__container-hero_section-texts > p {
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 33px;
}
/* data-section */
.corporate__container-data_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 61px;
}
.corporate__container-data_section-top {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.corporate__container-data_section-top > h1 {
  font-family: sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 34px;
  color: #000000;
}
.corporate__container-data_section-top > h1 > span {
  color: #6A65FF;
}
.corporate__container-data_section-bottom {
  display: flex;
  flex-wrap: wrap;
  margin: 69px 0 0 150px;
  align-items: center;
}
.corporate__container-data_section-bottom > img {
  margin-right: 95px;
  width: 50%;
}
.corporate__container-data_section-bottom > p {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  width: 460px;
  color: #000000;
}

/* editing section */
.corporate__container-editing {
  width: 100%;
  margin-top: 87px;
}
.corporate__container-editing > h1 {
  font-family: sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #121212;
}
.corporate__container-editing > h1 > span {
  color: #6A65FF;
}
.corporate__container-editing > img {
  margin-top: 68px;
  width: 100%;
}

/* blog section */
.corporate__container-blog_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 87px;
}
.corporate__container-blog_section-top {
  margin-left: 125px;
}
.corporate__container-blog_section-top > h1 {
  font-family: sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: #FF0000;
  /* text-align: left; */
}
.corporate__container-blog_section-bottom {
  width: 85%;
  display: flex;
  margin-top: 56px;
  margin-left: 125px;
  flex-basis: 30%;
  gap: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  color: #121212B2;
}
.corporate__container-blog_section-bottom_tile > h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  font-family: sans-serif;
  margin-top: 40px;
}
.corporate__container-blog_section-bottom_tile > img {
  width: 100%;
}

/* footer section */
.corporate__container-footer_section {
  background: #082642;
  margin-top: 87px;
  color: #FFFFFF;
}
.corporate__container-footer_section > small > span {
  color: #6A65FF;
}

@media (max-width: 1050px) {
  .corporate__container-hero_section {
    display: flex;
    flex-wrap: wrap;
    margin: 87px 0 0 80px;
  }
  .corporate__container-hero_section-texts {
    width: 45%;
    margin-right: 040px;
  }
  .corporate__container-hero_section-texts > h1 {
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    color: #FF0000;
    text-align: left;
  }
  .corporate__container-hero_section-texts > p {
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 33px;
  }
    /* data-section */
  .corporate__container-data_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 51px;
  }
  .corporate__container-data_section-top {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  .corporate__container-data_section-top > h1 {
    font-family: sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 34px;
    color: #000000;
  }
  .corporate__container-data_section-top > h1 > span {
    color: #6A65FF;
  }
  .corporate__container-data_section-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 69px 0 0 30px;
    align-items: center;
    justify-content: center;
  }
  .corporate__container-data_section-bottom > img {
    margin-right: 25px;
    width: 40%;
  }
  .corporate__container-data_section-bottom > p {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    max-width: 460px;
    width: 100%;
    color: #000000;
  }

}
@media (max-width: 850px) {
  /* data-section */
  .corporate__container-data_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 51px;
  }
  .corporate__container-data_section-top {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  .corporate__container-data_section-top > h1 {
    font-family: sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 34px;
    color: #000000;
  }
  .corporate__container-data_section-top > h1 > span {
    color: #6A65FF;
  }
  .corporate__container-data_section-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    text-align: center;
    margin: 69px 0 0 30px;
    align-items: center;
    justify-content: center;
  }
  .corporate__container-data_section-bottom > img {
    margin-right: 0px;
    width: 80%;
  }
  .corporate__container-data_section-bottom > p {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    max-width: 460px;
    width: 100%;
    color: #000000;
  }
  .corporate__container-blog_section-top {
    margin: 10px auto;
    width: 100%;
    text-align: center;
  }
  .corporate__container-blog_section-bottom {
    width: 100%;
    display: flex;
    margin-top: 56px;
    margin-left: 0px;
    flex-basis: 30%;
    gap: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    color: #121212B2;
  }
}
@media (max-width: 450px) {
  .corporate__container-hero_section {
    display: flex;
    flex-wrap: wrap;
    margin: 87px 0 0 0px;
  }
  .corporate__container-hero_section-texts {
    width: 90%;
    justify-content: center;
    text-align: center;
    gap: 50px;
    margin: 0 auto;
  }
  .corporate__container-hero_section-texts > h1 {
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    color: #FF0000;
    text-align: center;
  }
  .corporate__container-hero_section-texts > p {
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 33px;
    margin-bottom: 30px;
  }
  .corporate__container-hero_section-image {
    width: 100%;
    justify-content: center;
    display: flex;
  }
    /* data-section */
  .corporate__container-data_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 61px;
  }
  .corporate__container-data_section-top {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  .corporate__container-data_section-top > h1 {
    font-family: sans-serif;
    font-size: 23px;
    font-weight: 700;
    line-height: 34px;
    color: #000000;
  }
  .corporate__container-data_section-top > h1 > span {
    color: #6A65FF;
  }
  .corporate__container-data_section-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 69px auto 0px;
    align-items: center;
  }
  .corporate__container-data_section-bottom > img {
    margin: 0 auto;
    width: 100%;
  }
  .corporate__container-data_section-bottom > p {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    width: 460px;
    color: #000000;
  }
  .corporate__container-editing > h1 {
    font-family: sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
  }
  .corporate__container-editing > h1 > span {
    color: #6A65FF;
  }
}