.contact__container-get_in-touch {
  display: flex;
  flex-direction: column;
  margin-top: 71px;
  align-items: center;
  text-align: center;
}
.contact__container-get_in-touch > h1 {
  font-family: sans-serif;
  font-size: 29px;
  font-weight: 700;
  line-height: 36px;
  width: 630px;
  letter-spacing: -0.01em;
  color: #FF0000;
}
.contact__container-get_in-touch > p {
  margin-top: 35px;
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  color: #121212B2;
  width: 700px;
}
.contact__container-get_in-touch > img {
  margin-top: 40px;
}
/* forms section */
.contact__container-forms {
  display: flex;
  width: 80%;
  margin: 90px auto 0;
}
.contact__container-forms_left {
  display: flex;
  width: 55%;
  margin-right: 78px;
  flex-direction: column;
}
.contact__container-forms_left-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-basis: 40%;
}
.contact__container-forms_left-top > input {
  width: 44%;
  border: 1px solid #C4C4C480;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #12121299;
  padding: 25px;
  height: 60px;
}
.contact__container-forms_left > input {
  /* margin-top: 0px; */
  border: 1px solid #C4C4C480;
  padding: 25px;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #12121299;
}
.textarea {
  width: 100%;
  margin-top: 80px;
  height: 325px;
  padding: 20px;
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #12121299;
  border: 1px solid #C4C4C480;
}
.contact__container-forms_right {
  display: flex;
  flex-direction: column;
}
.contact__container-forms_right-top {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}
.contact__container-forms_right-top > h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #6A65FF;
}
.contact__container-forms_right-top > p {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #121212B2;
  margin-top: 16px;
}
.contact__container-forms_right-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  font-family: sans-serif;
  
}
.contact__container-forms_right-bottom > h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF0000;
}
.contact__container-forms_right-bottom > p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #121212B2;
  margin-top: 16px;
}
.contact-container-footer {
  background: #082642;
  margin-top: 80px;
  color: #fff;
}