.portfolio__container {
  width: 100%;
}
.portfolio__container-hero_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  text-align: center;
}
.portfolio__container-hero_section > h1 {
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  margin: 0 auto;
  color: #FF0000;
  width: 90%;
  text-align: center;
}
.portfolio__container-hero_section > p {
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #121212B2;
  margin: 40px auto 0;
  width: 90%;
  text-align: center;
}
.portfolio__container-hero_section > img {
  margin-top: 70px;
}

/* body-section */
.portfolio__container-body_section {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin: 187px auto 30px;
  flex-basis: 30% 35% 35%;
  gap: 50px;
  justify-content: center;;
}
.portfolio__container-body_section-1 {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin: 97px auto 30px;
  gap: 50px;
  flex-basis: 30% 35% 35%;
  justify-content: center;
}