.services__container {
  width: 100%;
}
.services__container-dnd {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.services__container-dnd_top {
  margin-top: 70px;
  font-family: sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #121212;
}
.services__container-dnd_top > h1 span:nth-child(1) {
  color: #FF0000;
}
.services__container-dnd_top > h1 span:nth-child(2) {
  color: #6A65FF ;
}
.services__container-dnd_bottom {
  display: flex;
  /* flex-wrap: wrap; */
  margin: 63px 120px 0 125px;
  align-items: center;
}
.services__container-dnd_bottom > img {
  max-width: 350px;
  width: 100%;
  height: 400px;
}
.services__container-dnd_bottom > p {
  font-family: sans-serif;
  width: 600px;
  /* width: 100%; */
  margin-left: 45px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
}
/* prduct-design section */
.services__container-product_design {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 110px;
}
.services__container-product_design > h1 {
  font-family: sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #FF0000;
}
.services__container-product_design > img {
  margin-top: 63px;
}

/* software engineering */
.services__container-software_engineering {
  display: flex;
  flex-direction: column;
}
.services__container-software_engineering-top > h1 {
  text-align: center;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  margin-top: 85px;
  color: #6A65FF;
}
.services__container-software_engineering-bottom {
  display: flex;
  margin: 65px 100px 0 125px;
  align-items: center;
}
.services__container-software_engineering-bottom > img {
  width: 450px;
  height: 300px;
  margin-right: 95px;

}
.services__container-software_engineering-bottom > p {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 600px;
  color: #000000;
}

/* video editing */
.services__container-video_editing {
  display: flex;
  flex-direction: column;
}
.services__container-video_editing-top > h1 {
  text-align: center;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  margin-top: 85px;
  color: #FF0000;
}
.services__container-video_editing-bottom {
  display: flex;
  margin: 65px 100px 0 125px;
  align-items: center;
}
.services__container-video_editing-bottom > img {
  max-width: 400px;
  width: 100%;
  height: 450px;
  margin-right: 145px;

}
.services__container-video_editing-bottom > p {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 600px;
  color: #000000;
}

@media (max-width: 1050px) {
  .services__container-dnd {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .services__container-dnd_top {
    margin-top: 50px;
    width: 100%;
    font-family: sans-serif;
    font-size: 23px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
  }
  .services__container-dnd_top > h1 span:nth-child(1) {
    color: #FF0000;
  }
  .services__container-dnd_top > h1 span:nth-child(2) {
    color: #6A65FF ;
  }
  .services__container-dnd_bottom {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 63px auto 0px;
    align-items: center;
  }
  .services__container-dnd_bottom > img {
    width: 350px;
    height: 350px;
    margin-bottom: 30px;
  }
  .services__container-dnd_bottom > p {
    font-family: sans-serif;
    max-width: 500px;
    margin-left: 25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  .services__container-product_design {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 80px;
  }
  
  /* software engineering */
  .services__container-software_engineering {
    display: flex;
    flex-direction: column;
  }
  .services__container-software_engineering-top > h1 {
    text-align: center;
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    margin-top: 85px;
    color: #6A65FF;
  }
  .services__container-software_engineering-bottom {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 65px auto 0;
    align-items: center;
  }
  .services__container-software_engineering-bottom > img {
    width: 350px;
    height: 300px;
    margin-right: 30px;
    margin-bottom: 30px;

  }
  .services__container-software_engineering-bottom > p {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    width: 500px;
    color: #000000;
  }

  /* video editing */
  .services__container-video_editing {
    display: flex;
    flex-direction: column;
  }
  .services__container-video_editing-top > h1 {
    text-align: center;
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    margin-top: 65px;
    color: #FF0000;
  }
  .services__container-video_editing-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: 65px auto 0;
    align-items: center;
  }
  .services__container-video_editing-bottom > img {
    max-width: 300px;
    height: 400px;
    margin-right: 30px;
    margin-bottom: 30px;

  }
  .services__container-video_editing-bottom > p {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    width: 500px;
    color: #000000;
  }

}
@media (max-width: 850px) {
  .services__container-dnd {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .services__container-dnd_top {
    margin-top: 70px;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
  }
  .services__container-dnd_top > h1 span:nth-child(1) {
    color: #FF0000;
  }
  .services__container-dnd_top > h1 span:nth-child(2) {
    color: #6A65FF ;
  }
  .services__container-dnd_bottom {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    margin: 63px auto 0;
    align-items: center;
  }
  .services__container-dnd_bottom > img {
    max-width: 350px;
    width: 100%;
    height: 400px;
  }
  .services__container-dnd_bottom > p {
    font-family: sans-serif;
    max-width: 600px;
    width: 90%;
    text-align: center;
    margin-left: 45px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  /* prduct-design section */
  .services__container-product_design {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
  }
  .services__container-product_design > h1 {
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF0000;
  }
  .services__container-product_design > img {
    margin-top: 63px;
    width: 100%;
  }
  
  /* software engineering */
  .services__container-software_engineering {
    display: flex;
    flex-direction: column;
  }
  .services__container-software_engineering-top > h1 {
    text-align: center;
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    margin-top: 85px;
    color: #6A65FF;
  }
  .services__container-software_engineering-bottom {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 65px auto 0;
    align-items: center;
  }
  .services__container-software_engineering-bottom > img {
    max-width: 450px;
    width: 100%;
    height: 300px;
    margin-right: 0px;
  
  }
  .services__container-software_engineering-bottom > p {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 600px;
    width: 90%;
    color: #000000;
  }
  
  /* video editing */
  .services__container-video_editing {
    display: flex;
    flex-direction: column;
  }
  .services__container-video_editing-top > h1 {
    text-align: center;
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    margin-top: 65px;
    color: #FF0000;
  }
  .services__container-video_editing-bottom {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 65px auto 0;
    align-items: center;
  }
  .services__container-video_editing-bottom > img {
    max-width: 400px;
    width: 100%;
    height: 450px;
    margin-right: 0;
  
  }
  .services__container-video_editing-bottom > p {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 600px;
    width: 90%;
    color: #000000;
  }
}
@media (max-width: 425px) {
  .services__container-dnd_bottom > p {
    font-family: sans-serif;
    /* max-width: 600px; */
    width: 90%;
    text-align: center;
    margin: auto;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  
}