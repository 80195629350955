* {
  padding: 0;
  margin: 0; 
  box-sizing: border-box;
}
.home__container-hambuger {
  display: none;
  margin-right: 20px;
  position: relative;
}
.home__container {
  width: 100%;
  background-color: #fff;
}
.home__container-header_part {
  background-color: #082642;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.home__container-header_part-top_nav {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 25px;
}
.home__container-header_part-logo {
  display: flex;
  margin-left: 125px;
}
.home__container-header_part-right {
  display: flex;
  align-items: center;
}
.home__container-header_part-top_nav-text {
  width: 560px;
  display: flex;
  color: #fff;
  justify-content: space-between;
  margin-right: 30px;
  font-family: sans-serif;
}
.nav-link {
  font-family: Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  letter-spacing: 0.1em;
  align-items: center;
  color: #FFFFFF90;
}
.nav-link:hover {
  border-bottom: 1px solid white;
  color: #fff;
  transition: 2s;
}

.home__container-header_part-top_nav-button {
  margin-right: 80px;
}
.home__container-header_part-top_nav-button > button {
  width: 148px;
  padding: 10px 33px 10px 33px;
  border-radius: 18px;
  border: 1px solid #fff;
  gap: 10px;
  cursor: pointer;
  background-color: #082642;
  color: #fff;
}
.home__container-header_part-content {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 60px auto 97px;
  color: #ffffff;
  align-items: center;
  justify-content: space-between;
}
.home__container-header_part-content_text {
  display: flex;
  flex-basis: 60%;
  flex-direction: column;
}
.home__container-header_part-content_text > h2 {
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}
.home__container-header_part-content_text > p {
  margin-top: 40px;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.em;
  text-align: left;
  width: 450px;
}
.home__container-header_part-content_text > button {
  background-color: #6A65FFE5;
  width: 148px;
  padding: 10px 33px 10px 33px;
  border-radius: 18px;
  gap: 10px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  margin-top: 45px;
  margin-left: 45px;
  outline: none;
  font-family: Sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
}
.home__container-header_part-content_image > img {
  width: 100%;
}

/* FOCUSED ON */
.home__container-focused_on {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 90px;
}
.home__container-focused_on-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}
.home__container-focused_on-top > h1 {
  
  font-family: Sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
}
.home__container-focused_on-top > h1 > span {
  color: #6A65FF;
}
.home__container-focused_on-bottom {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 90px auto 0;
  align-items: center;
  /* justify-content: space-around; */
}
.home__container-focused_on-bottom > img{
  /* flex-basis: 50%; */
  width: 450px;
  margin-right: 120px;
  height: 300px;
}
.home__container-focused_on-bottom > p {
  /* flex-basis: 40%; */
  font-family: sans-serif;
  width: 450px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

/* Explore-creatives */
.home__container-explore_creatives {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.home__container-explore_creatives-top {
  margin: 90px auto 0;
}
.home__container-explore_creatives-top > h2 {
  color: #FF0000;
  font-family: sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
}
.home__container-explore_creatives-top > h2 > span {
  color: #6A65FF;
}
.home__container-explore_creatives-bottom {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  margin: 90px auto 0;
  flex-basis: 20%;
  justify-content: space-between;
}
.home__container-explore_creatives-bottom_1 {
  background: #F2E9FA;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  width: 275px;
  height: 275px;
}
.home__container-explore_creatives-bottom_1:hover {
  opacity: 0.5;
}
.home__container-explore_creatives-bottom_1 > p {
  width: 187px;
  height: 24px;
  font-family: sans-serif;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  line-height: 24px;
}
.home__container-explore_creatives-bottom_2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  width: 275px;
  height: 275px;
  background: #EBF5FF;
}
.home__container-explore_creatives-bottom_2:hover {
  opacity: 0.5;
}
.home__container-explore_creatives-bottom_2 > p {
  width: 187px;
  height: 24px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 24px;
}
.home__container-explore_creatives-bottom_3 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  width: 275px;
  height: 275px;
  background: #FDE4C380;
}
.home__container-explore_creatives-bottom_3:hover {
  opacity: 0.5;
}
.home__container-explore_creatives-bottom_3 > p {
  width: 187px;
  height: 24px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 24px;
}
.home__container-explore_creatives-bottom_4  {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  width: 275px;
  height: 275px;
  background: pink;
}
.home__container-explore_creatives-bottom_4:hover {
  opacity: 0.5;
}
.home__container-explore_creatives-bottom_4 > p {
  width: 187px;
  height: 24px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 24px;
}

/* satisfied clients */
.home__container-satisfied_clients {
  width: 100%;
  background: #000000;
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  color: #ffffff;
}
.home__container-satisfied_clients-top {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}
.home__container-satisfied_clients-top > h2 {
  font-family: sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.01em;
}
.home__container-satisfied_clients-bottom {
  width: 70%;
  margin: 80px auto 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.home__container-satisfied_clients-bottom_tile {
  width: 200px;
  justify-content: center;
  text-align: center;
  font-family: sans-serif;
  letter-spacing: 0.01em;
}
.home__container-satisfied_clients-bottom_tile > p {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}
.home__container-satisfied_clients-bottom_tile > h4 {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 30px;
}
/* products */
.home__container-products {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.home__container-products_image {
  margin-left: 100px;
}
.home__container-products_image > img {
  height: 430px;
  width: 430px;
}
.home__container-products_text {
  margin-left: 120px;
  font-family: sans-serif;
}
.home__container-products_text > h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  color: #FF0000;
  letter-spacing: 0em;
  margin-bottom: 40px;
}
.home__container-products_text > p {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  width: 400px;
  letter-spacing: 0em;
}
.home__container-products_text > h4 {
  font-family: sans-serif;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2484DC;
}

/* Learning section */
.home__container-learning_section {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}
.home__container-learning_section > h2 {
  font-family: sans-serif;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.02em;
}
.home__container-learning_section > h2 > span {
  color: #6A65FF;
}
.home__container-learning_section > img {
  margin-top: 70px;
}

/* teamwork */
.home__container-teamwork {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 90px auto 0;
}
.home__container-teamwork_top {
  display: flex;
  flex-direction: column;
}
.home__container-teamwork_top > h2 {
  font-family: sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}
.home__container-teamwork_top > h2 > span {
  color: #6A65FF;
}
.home__container-teamwork_top > p {
  margin-top: 25px;
  max-width: 360px;
  width: 100%;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
.home__container-teamwork_bottom {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 23px;
  justify-content: space-between;
  margin-top: 65px;
}
.home__container-teamwork_bottom-tile_1 {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
}
.home__container-teamwork_bottom-tile_1 > h3 {
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 8px;
}
.home__container-teamwork_bottom-tile_1 > p {
  margin-top: 23px;
  width: 352px;
}
.home__container-teamwork_bottom-tile_1 > img {
  margin-top: 10px;
  margin-right: 0;
  width: 90px;
  height: 70px;
  margin-left: 250px;
}
.home__container-teamwork_bottom-tile_2 {
  display: flex;
  margin-top: 120px;
  flex-direction: column;
}
.home__container-teamwork_bottom-tile_2 > h3 {
  font-family: sans-serif;
  color: #6A65FF;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 8px;
}
.home__container-teamwork_bottom-tile_2 > p {
  margin-top: 23px;
  width: 352px;
}
.home__container-teamwork_bottom-tile_2 > img {
  margin-top: 10px;
  margin-right: 0;
  width: 90px;
  height: 70px;
  margin-left: 250px;
}
.home__container-teamwork_bottom-tile_3 {
  display: flex;
  margin-top: 250px;
  flex-direction: column;
}
.home__container-teamwork_bottom-tile_3 > h3 {
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 8px;
}
.home__container-teamwork_bottom-tile_3 > p {
  margin-top: 23px;
  width: 352px;
}
.home__container-teamwork_bottom-tile_3 > img {
  margin-top: 10px;
  margin-right: 0;
  width: 90px;
  height: 70px;
  margin-left: 250px;
}


@media (max-width: 1050px) {
  .home__container {
    width: 100%;
    background-color: #fff;
  }
  .home__container-header_part {
    background-color: #082642;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .home__container-header_part-top_nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
  }
  .home__container-header_part-logo {
    display: flex;
    margin: 0px 0px 0px 80px;
  }
  .home__container-header_part-right {
    display: flex;
    align-items: right;
    justify-content: space-around;
    width: 70%;
  }
  .home__container-header_part-top_nav-text {
    width: 30em;
    display: flex;
    color: #fff;
    justify-content: space-between;
    margin-right: 25px;
    font-family: sans-serif;
  }

  .nav-link {
    font-family: Sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: none;
    letter-spacing: 0.1em;
    align-items: center;
    color: #FFFFFF90;
  }
  
  
  .home__container-header_part-top_nav-button {
    margin-right: 1px;
  }
  .home__container-header_part-top_nav-button > button {
    width: 148px;
    padding: 10px 33px 10px 33px;
    border-radius: 18px;
    border: 1px solid #fff;
    gap: 1px;
    background-color: #082642;
    color: #fff;
  }
  .home__container-header_part-content {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 60px auto 97px;
    color: #ffffff;
    align-items: center;
    justify-content: space-between;
  }
  .home__container-header_part-content_text {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
  }
  .home__container-header_part-content_text > h2 {
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
  }
  .home__container-header_part-content_text > p {
    margin-top: 40px;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.em;
    text-align: left;
    width: 450px;
  }
  .home__container-header_part-content_text > button {
    background-color: #6A65FFE5;
    width: 148px;
    padding: 10px 33px 10px 33px;
    border-radius: 18px;
    gap: 10px;
    color: #ffffff;
    border: none;
    margin-top: 45px;
    outline: none;
    font-family: Sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
  }
  
  /* FOCUSED ON */
  .home__container-focused_on {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 90px;
  }
  .home__container-focused_on-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
  }
  .home__container-focused_on-top > h1 {
    
    font-family: Sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .home__container-focused_on-top > h1 > span {
    color: #6A65FF;
  }
  .home__container-focused_on-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 90px auto 0;
    align-items: center;
    /* justify-content: space-around; */
  }
  .home__container-focused_on-bottom > img{
    /* flex-basis: 50%; */
    width: 40%;
    margin-right: 5rem;
  }
  .home__container-focused_on-bottom > p {
    /* flex-basis: 40%; */
    font-family: sans-serif;
    width: 45%;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  
  }
  
  /* Explore-creatives */
  .home__container-explore_creatives {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .home__container-explore_creatives-top {
    margin: 90px auto 0;
  }
  .home__container-explore_creatives-top > h2 {
    color: #FF0000;
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
  }
  .home__container-explore_creatives-bottom {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    margin: 90px auto 0;
    flex-basis: 20%;
    justify-content: space-between;
  }
  .home__container-explore_creatives-bottom_1 {
    background: #F2E9FA;
    display: flex;
    flex-direction: column;
    gap: 30px ;
    border-radius: 7px;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
    width: 275px;
    height: 275px;
  }
  .home__container-explore_creatives-bottom_1 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_2 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    margin-bottom: 30px;
    background: #EBF5FF;
  }
  .home__container-explore_creatives-bottom_2 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_3 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    margin-bottom: 30px;
    background: #FDE4C380;
  }
  .home__container-explore_creatives-bottom_3 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_4  {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    background: pink;
  }
  .home__container-explore_creatives-bottom_4 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  
  /* satisfied clients */
  .home__container-satisfied_clients {
    width: 100%;
    background: #000000;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    color: #ffffff;
  }
  .home__container-satisfied_clients-top {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  .home__container-satisfied_clients-top > h2 {
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0.01em;
  }
  .home__container-satisfied_clients-bottom {
    width: 80%;
    margin: 80px auto 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .home__container-satisfied_clients-bottom_tile {
    width: 200px;
    justify-content: center;
    text-align: center;
    font-family: sans-serif;
    letter-spacing: 0.01em;
  }
  .home__container-satisfied_clients-bottom_tile > p {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
  }
  .home__container-satisfied_clients-bottom_tile > h4 {
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
    margin-top: 30px;
  }
  /* products */
  .home__container-products {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .home__container-products_image {
    margin-left: 49px;
    margin-bottom: 30px;
  }
  .home__container-products_image > img {
    height: 430px;
    width: 430px;
  }
  .home__container-products_text {
    margin-left: 60px;
    font-family: sans-serif;
  }
  .home__container-products_text > h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    color: #FF0000;
    letter-spacing: 0em;
    margin-bottom: 40px;
  }
  .home__container-products_text > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 400px;
    letter-spacing: 0em;
  }
  .home__container-products_text > h4 {
    font-family: sans-serif;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #2484DC;
  }
  
  /* Learning section */
  .home__container-learning_section {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
  }
  .home__container-learning_section > h2 {
    font-family: sans-serif;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0.02em;
  }
  .home__container-learning_section > h2 > span {
    color: #6A65FF;
  }
  .home__container-learning_section > img {
    margin-top: 70px;
  }
  
  /* teamwork */
  .home__container-teamwork {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 90px auto 0;
  }
  .home__container-teamwork_top {
    display: flex;
    flex-direction: column;
  }
  .home__container-teamwork_top > h2 {
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
  }
  .home__container-teamwork_top > h2 > span {
    color: #6A65FF;
  }
  .home__container-teamwork_top > p {
    margin-top: 25px;
    width: 360px;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
  .home__container-teamwork_bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 23px;
    justify-content: space-between;
    margin-top: 65px;
  }
  .home__container-teamwork_bottom-tile_1 {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_1 > h3 {
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 8px;
  }
  .home__container-teamwork_bottom-tile_1 > p {
    margin-top: 23px;
    width: 352px;
  }
  .home__container-teamwork_bottom-tile_1 > img {
    margin-top: 10px;
    margin-right: 0;
    width: 90px;
    height: 70px;
    margin-left: 250px;
  }
  .home__container-teamwork_bottom-tile_2 {
    display: flex;
    margin-top: 120px;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_2 > h3 {
    font-family: sans-serif;
    color: #6A65FF;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 8px;
  }
  .home__container-teamwork_bottom-tile_2 > p {
    margin-top: 23px;
    width: 352px;
  }
  .home__container-teamwork_bottom-tile_2 > img {
    margin-top: 10px;
    margin-right: 0;
    width: 90px;
    height: 70px;
    margin-left: 250px;
  }
  .home__container-teamwork_bottom-tile_3 {
    display: flex;
    margin-top: 250px;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_3 > h3 {
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 8px;
  }
  .home__container-teamwork_bottom-tile_3 > p {
    margin-top: 23px;
    width: 352px;
  }
  .home__container-teamwork_bottom-tile_3 > img {
    margin-top: 10px;
    margin-right: 0;
    width: 90px;
    height: 70px;
    margin-left: 250px;
  }
  
}

@media (max-width: 850px) {
  .home__container {
    width: 100%;
    background-color: #fff;
  }
  .home__container-header_part {
    background-color: #082642;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .home__container-header_part-top_nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
  }
  .home__container-header_part-logo {
    display: flex;
    margin: 0px 0 0 30px;
  }
  .home__container-header_part-right {
    display: flex;
    align-items: center;
  }
  .home__container-header_part-top_nav-text {
    width: 30em;
    display: flex;
    color: #fff;
    justify-content: space-between;
    margin-right: 25px;
    font-family: sans-serif;
  }
  .nav-link {
    font-family: Sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: none;
    letter-spacing: 0.1em;
    align-items: center;
    color: #FFFFFF90;
  }
  
  .home__container-header_part-top_nav-button {
    margin-right: 2rem;
  }
  .home__container-header_part-top_nav-button > button {
    width: 148px;
    padding: 10px 33px 10px 33px;
    border-radius: 18px;
    border: 1px solid #fff;
    background-color: #082642;
    color: #fff;
  }
  .home__container-header_part-content {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 60px auto 97px;
    color: #ffffff;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .home__container-header_part-content_text {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
  }
  .home__container-header_part-content_text > h2 {
    font-family: sans-serif;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
  }
  .home__container-header_part-content_text > p {
    margin-top: 40px;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.em;
    display: flex;
    justify-content: left;
    text-align: left;
    width: 70%;
  }
  .home__container-header_part-content_text > button {
    background-color: #6A65FFE5;
    width: 148px;
    padding: 10px 33px 10px 33px;
    border-radius: 18px;
    gap: 10px;
    color: #ffffff;
    border: none;
    margin: 45px 0 45px 10px ;
    outline: none;
    font-family: Sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
  }
  
  /* FOCUSED ON */
  .home__container-focused_on {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 90px;
  }
  .home__container-focused_on-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
  }
  .home__container-focused_on-top > h1 {
    
    font-family: Sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .home__container-focused_on-top > h1 > span {
    color: #6A65FF;
  }
  .home__container-focused_on-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 90px auto 0;
    align-items: center;
    /* justify-content: space-around; */
  }
  .home__container-focused_on-bottom > img{
    /* flex-basis: 50%; */
    width: 40%;
    margin-right: 5rem;
  }
  .home__container-focused_on-bottom > p {
    /* flex-basis: 40%; */
    font-family: sans-serif;
    width: 45%;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  
  }
  
  /* Explore-creatives */
  .home__container-explore_creatives {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .home__container-explore_creatives-top {
    margin: 90px auto 0;
  }
  .home__container-explore_creatives-top > h2 {
    color: #FF0000;
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
  }
  .home__container-explore_creatives-bottom {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    margin: 90px auto 0;
    flex-basis: 20%;
    justify-content: space-between;
  }
  .home__container-explore_creatives-bottom_1 {
    background: #F2E9FA;
    display: flex;
    flex-direction: column;
    gap: 30px ;
    border-radius: 7px;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
    width: 275px;
    height: 275px;
  }
  .home__container-explore_creatives-bottom_1 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_2 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    margin-bottom: 30px;
    background: #EBF5FF;
  }
  .home__container-explore_creatives-bottom_2 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_3 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    margin-bottom: 30px;
    background: #FDE4C380;
  }
  .home__container-explore_creatives-bottom_3 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_4  {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    background: pink;
  }
  .home__container-explore_creatives-bottom_4 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  
  /* satisfied clients */
  .home__container-satisfied_clients {
    width: 100%;
    background: #000000;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    color: #ffffff;
  }
  .home__container-satisfied_clients-top {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  .home__container-satisfied_clients-top > h2 {
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0.01em;
  }
  .home__container-satisfied_clients-bottom {
    width: 100%;
    margin: 80px auto 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .home__container-satisfied_clients-bottom_tile {
    width: 200px;
    margin-bottom: 30px;
    justify-content: center;
    text-align: center;
    font-family: sans-serif;
    letter-spacing: 0.01em;
  }
  .home__container-satisfied_clients-bottom_tile > p {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
  }
  .home__container-satisfied_clients-bottom_tile > h4 {
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
    margin-top: 30px;
  }
  /* products */
  .home__container-products {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .home__container-products_image {
    margin-left: 49px;
    margin-bottom: 30px;
  }
  .home__container-products_image > img {
    height: 430px;
    width: 430px;
  }
  .home__container-products_text {
    margin-left: 60px;
    font-family: sans-serif;
    text-align: center;
  }
  .home__container-products_text > h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    color: #FF0000;
    letter-spacing: 0em;
    margin-bottom: 40px;
  }
  .home__container-products_text > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 400px;
    letter-spacing: 0em;
  }
  .home__container-products_text > h4 {
    font-family: sans-serif;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #2484DC;
  }
  
  /* Learning section */
  .home__container-learning_section {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
  }
  .home__container-learning_section > h2 {
    font-family: sans-serif;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0.02em;
  }
  .home__container-learning_section > h2 > span {
    color: #6A65FF;
  }
  .home__container-learning_section > img {
    margin-top: 70px;
  }
  
  /* teamwork */
  .home__container-teamwork {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 90px auto 0;
    justify-content: center;
  }
  .home__container-teamwork_top {
    display: flex;
    flex-direction: column;
  }
  .home__container-teamwork_top > h2 {
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
  }
  .home__container-teamwork_top > h2 > span {
    color: #6A65FF;
  }
  .home__container-teamwork_top > p {
    margin-top: 25px;
    width: 360px;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
  .home__container-teamwork_bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 23px;
    justify-content: left;
    margin-top: 65px;
  }
  .home__container-teamwork_bottom-tile_1 {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_1 > h3 {
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 8px;
  }
  .home__container-teamwork_bottom-tile_1 > p {
    margin-top: 23px;
    width: 352px;
  }
  .home__container-teamwork_bottom-tile_1 > img {
    margin: 10px ;    
    width: 90px;
    height: 70px;
    margin-left: 30px;
  }
  .home__container-teamwork_bottom-tile_2 {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_2 > h3 {
    font-family: sans-serif;
    color: #6A65FF;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 8px;
  }
  .home__container-teamwork_bottom-tile_2 > p {
    margin-top: 23px;
    width: 352px;
  }
  .home__container-teamwork_bottom-tile_2 > img {
    margin-top: 10px;
    width: 90px;
    height: 70px;
    margin-left: 30px;
  }
  .home__container-teamwork_bottom-tile_3 {
    display: flex;
    margin-top: 60px;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_3 > h3 {
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 8px;
  }
  .home__container-teamwork_bottom-tile_3 > p {
    margin-top: 23px;
    width: 352px;
  }
  .home__container-teamwork_bottom-tile_3 > img {
    margin-top: 10px;
    margin-right: 0;
    width: 90px;
    height: 70px;
    margin-left: 250px;
  }
}
@media (max-width: 450px) {
  .home__container {
    width: 100%;
    background-color: #fff;
  }
  .home__container-header_part {
    background-color: #082642;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .home__container-header_part-top_nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
  }
  .home__container-header_part-logo {
    display: flex;
    margin: 0px 0 0 25px;
  }
  .home__container-header_part-right {
    display: none;
    align-items: center;
  }
  .home__container-header_part-top_nav-text {
    width: 30em;
    display: flex;
    color: #fff;
    justify-content: space-between;
    margin-right: 25px;
    font-family: sans-serif;
  }
  .nav-link {
    font-family: Sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: none;
    letter-spacing: 0.1em;
    align-items: center;
    color: #FFFFFF90;
  }
  
  .home__container-header_part-top_nav-button {
    margin-right: 2rem;
  }
  .home__container-header_part-top_nav-button > button {
    width: 148px;
    padding: 10px 33px 10px 33px;
    border-radius: 18px;
    border: 1px solid #fff;
    background-color: #082642;
    color: #fff;
  }
  .home__container-header_part-content {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 45px auto 97px;
    color: #ffffff;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .home__container-header_part-content_text {
    display: flex;
    flex-basis: 90%;
    flex-direction: column;
  }
  .home__container-header_part-content_text > h2 {
    font-family: sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

  }
  .home__container-header_part-content_text > p {
    margin-top: 30px;
    font-family: sans-serif;
    display: flex;
    justify-content: left;
    width: 90%;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
  }
  .home__container-header_part-content_text > button {
    background-color: #6A65FFE5;
    width: 148px;
    padding: 10px 33px 10px 33px;
    border-radius: 18px;
    gap: 10px;
    color: #ffffff;
    border: none;
    margin: 42px 0 42px 10px ;
    outline: none;
    font-family: Sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
  }
  .home__container-header_part-content_image > img {
    width: 100%;
  }
  
  /* FOCUSED ON */
  .home__container-focused_on {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 90px;
  }
  .home__container-focused_on-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
  }
  .home__container-focused_on-top > h1 {
    width: 100%;
    text-align: center;
    font-family: Sans-serif;
    font-weight: 700;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;

  }
  .home__container-focused_on-top > h1 > span {
    color: #6A65FF;
  }
  .home__container-focused_on-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 60px auto 0;
    align-items: center;
    /* justify-content: space-around; */
  }
  .home__container-focused_on-bottom > img{
    /* flex-basis: 50%; */
    width: 80%;
    margin: 0 auto;
  }
  .home__container-focused_on-bottom > p {
    /* flex-basis: 40%; */
    font-family: sans-serif;
    margin: 39px auto 0;
    width: 85%;
    letter-spacing: 0em;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

  }
  
  /* Explore-creatives */
  .home__container-explore_creatives {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .home__container-explore_creatives-top {
    margin: 90px auto 0;
  }
  .home__container-explore_creatives-top > h2 {
    color: #FF0000;
    font-family: sans-serif;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;

  }
  .home__container-explore_creatives-bottom {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    margin: 30px auto 0;
    flex-basis: 20%;
    justify-content: center;
  }
  .home__container-explore_creatives-bottom_1 {
    background: #F2E9FA;
    display: flex;
    flex-direction: column;
    gap: 30px ;
    border-radius: 7px;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
    width: 275px;
    height: 275px;
  }
  .home__container-explore_creatives-bottom_1 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_2 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    margin-bottom: 30px;
    background: #EBF5FF;
  }
  .home__container-explore_creatives-bottom_2 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_3 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    margin-bottom: 30px;
    background: #FDE4C380;
  }
  .home__container-explore_creatives-bottom_3 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  .home__container-explore_creatives-bottom_4  {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    background: pink;
  }
  .home__container-explore_creatives-bottom_4 > p {
    width: 187px;
    height: 24px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }
  
  /* satisfied clients */
  .home__container-satisfied_clients {
    width: 100%;
    background: #000000;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    color: #ffffff;
  }
  .home__container-satisfied_clients-top {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  .home__container-satisfied_clients-top > h2 {
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0.01em;
  }
  .home__container-satisfied_clients-bottom {
    width: 100%;
    margin: 80px auto 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .home__container-satisfied_clients-bottom_tile {
    width: 200px;
    margin-bottom: 30px;
    justify-content: center;
    text-align: center;
    font-family: sans-serif;
    letter-spacing: 0.01em;
  }
  .home__container-satisfied_clients-bottom_tile > p {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
  }
  .home__container-satisfied_clients-bottom_tile > h4 {
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
    margin-top: 30px;
  }
  /* products */
  .home__container-products {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .home__container-products_image {
    margin-left: 49px;
    margin-bottom: 30px;
  }
  .home__container-products_image > img {
    height: 100%;
    width: 100%;
  }
  .home__container-products_text {
    margin: 0 auto;
    width: 90%;
    font-family: sans-serif;
    text-align: center;
  }
  .home__container-products_text > h2 {
    color: #FF0000;
    margin-bottom: 40px;
    font-family: sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;

  }
  .home__container-products_text > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    letter-spacing: 0em;
    margin-top: 39px;
    text-align: left;
  }
  .home__container-products_text > h4 {
    font-family: sans-serif;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #2484DC;
    display: none;
  }
  
  /* Learning section */
  .home__container-learning_section {
    margin-top: 39px;
    display: flex;
    flex-direction: column;
  }
  .home__container-learning_section > h2 {
    font-family: sans-serif;
    text-align: center;
    letter-spacing: 0.02em;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    width: 60%;
    margin: 0 auto;
  }
  .home__container-learning_section > h2 > span {
    color: #6A65FF;
  }
  .home__container-learning_section > img {
    margin-top: 40px;
    width: 100%;
  }
  
  /* teamwork */
  .home__container-teamwork {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 360px;
    margin: 39px auto 0;
    justify-content: center;
  }
  .home__container-teamwork_top {
    display: flex;
    flex-direction: column;
  }
  .home__container-teamwork_top > h2 {
    font-family: sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;

  }
  .home__container-teamwork_top > h2 > span {
    color: #6A65FF;
  }
  .home__container-teamwork_top > p {
    margin-top: 25px;
    max-width: 360px;
    width: 95%;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
  .home__container-teamwork_bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 23px;
    justify-content: left;
    margin-top: 65px;
  }
  .home__container-teamwork_bottom-tile_1 {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_1 > h3 {
    font-family: sans-serif;
    margin-left: 8px;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

  }
  .home__container-teamwork_bottom-tile_1 > p {
    margin-top: 23px;
    max-width: 352px;
    width: 95%;
  }
  .home__container-teamwork_bottom-tile_1 > img {
    margin: 10px ;    
    width: 50px;
    height: 30px;
    margin-left: 30px;
  }
  .home__container-teamwork_bottom-tile_2 {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_2 > h3 {
    font-family: sans-serif;
    color: #6A65FF;
    margin-left: 8px;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

  }
  .home__container-teamwork_bottom-tile_2 > p {
    margin-top: 23px;
    max-width: 352px;
    width: 100%;
  }
  .home__container-teamwork_bottom-tile_2 > img {
    margin-top: 10px;
    width: 50px;
    height: 30px;
    margin-left: 30px;
  }
  .home__container-teamwork_bottom-tile_3 {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
  }
  .home__container-teamwork_bottom-tile_3 > h3 {
    font-family: sans-serif;
    margin-left: 8px;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

  }
  .home__container-teamwork_bottom-tile_3 > p {
    margin-top: 23px;
    max-width: 352px;
    width: 100%;
  }
  .home__container-teamwork_bottom-tile_3 > img {
    margin-top: 10px;
    margin-right: 0;
    width: 90px;
    height: 70px;
    margin-left: 250px;
  }
  /* hambuger-sect */
  .home__container-hambuger {
    display: flex;
    top: 30px;
    right: 20px;
    transition: 1s;
    color: white;
    margin-bottom: 10px;
    position: absolute;
  }
  .home__container-hambuger_links {
    display: block;
    position: absolute;
    top: 80px;
    right: 0px;
  }
  .home__container-hambuger_links-container {
    background: #efefef;
    width: 200px;
    height: 200px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
    justify-content: space-around;
    list-style: none;
  }
  .ham-link {
    text-decoration: none;
    font-family: sans-serif;
    color: #000000;
    text-align: center;
    margin: 30px;
  }
  .ham-link:hover {
    border-bottom: 1px solid black;
  }
}